var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),(!_vm.setting.id)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"media"},[_c('div',{staticClass:"media-body overflow-hidden text-center mt-4"},[_c('h5',{staticClass:"text-truncate font-size-15"},[_vm._v("Olá, "+_vm._s(_vm.user.name)+".")]),_c('p',{staticClass:"text-muted"},[_vm._v("Indentificamos que a sua plataforma ainda não está configurada.")]),_c('b-button',{staticClass:"mt-4 mb-4",attrs:{"variant":"primary","size":"lg"},on:{"click":function($event){return _vm.initSetting()}}},[_c('i',{staticClass:"bx bx-cog font-size-16 align-middle mr-2"}),_vm._v(" CONFIGURAR AGORA! ")])],1)])])])])]):_vm._e(),(_vm.setting.id || _vm.configEvent)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveSetting()}}},[_c('div',{staticClass:"form-group row mb-4"},[_c('label',{staticClass:"col-form-label col-lg-2",attrs:{"for":"name"}},[_vm._v("Nome do Evento")]),_c('div',{staticClass:"col-lg-10"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.setting.name),expression:"setting.name"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.submitted && _vm.$v.setting.name.$error,
                  },attrs:{"id":"name","name":"name","type":"text","placeholder":"Inform o nome do evento"},domProps:{"value":(_vm.setting.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.setting, "name", $event.target.value)}}})])]),_c('div',{staticClass:"form-group row mb-4"},[_c('label',{staticClass:"col-form-label col-lg-2",attrs:{"for":"description"}},[_vm._v("Descrição do Evento")]),_c('div',{staticClass:"col-lg-10"},[_c('ckeditor',{class:{
                    'is-invalid': _vm.submitted && _vm.$v.setting.description.$error,
                  },attrs:{"editor":_vm.editor,"config":_vm.editorConfig},model:{value:(_vm.setting.description),callback:function ($$v) {_vm.$set(_vm.setting, "description", $$v)},expression:"setting.description"}})],1)]),_c('div',{staticClass:"form-group row mb-4"},[_c('label',{staticClass:"col-form-label col-lg-2",attrs:{"for":"name"}},[_vm._v("E-mail de Contato")]),_c('div',{staticClass:"col-lg-10"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.setting.contact_email),expression:"setting.contact_email"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.submitted && _vm.$v.setting.contact_email.$error,
                  },attrs:{"id":"name","name":"name","type":"text","placeholder":"Informe um e-mail para contato"},domProps:{"value":(_vm.setting.contact_email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.setting, "contact_email", $event.target.value)}}})])]),_c('div',{staticClass:"form-group row mb-4 actions"},[_c('label',{staticClass:"col-form-label col-lg-2",attrs:{"for":"name"}},[_vm._v("Logotipo")]),_c('div',{staticClass:"col-lg-10"},[_c('input',{ref:"logo",attrs:{"type":"file","name":"logo","accept":"image/*"},on:{"change":_vm.setImage}})])]),_c('div',{staticClass:"form-group row mb-4"},[_c('label',{staticClass:"col-form-label col-lg-2",attrs:{"for":"name"}},[_vm._v("Banner Home")]),_c('div',{staticClass:"col-lg-10 actions"},[_c('input',{ref:"bannerhome",attrs:{"type":"file","name":"bannerhome","accept":"image/*"},on:{"change":_vm.setImageBH}}),(_vm.setting.banner)?_c('div',{staticClass:"popup-gallery border-dashed link",staticStyle:{"max-width":"132px"}},[_c('img',{attrs:{"src":_vm.cropBannerHome || _vm.setting.banner,"width":"120"},on:{"click":function($event){_vm.singleImageBH = true}}}),_c('vue-easy-lightbox',{attrs:{"visible":_vm.singleImageBH,"imgs":_vm.setting.banner},on:{"hide":function($event){_vm.singleImageBH = false}}})],1):_vm._e()])]),_c('div',{staticClass:"mt-5"},[_c('button',{staticClass:"btn btn-primary mr-1",attrs:{"type":"submit"}},[_vm._v(" Salvar ")])])])])])])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }