import * as types from './mutation-types'
let defaultObject = () => ({
  data: {
  }
})

export default {
  [types.SET_RDSTATION] (state, payload) {
    if (payload === null) {
      payload = defaultObject()
    }
    state.rdstation = payload
  }
}
