<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row" v-if="!setting.id">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="media">
              <div class="media-body overflow-hidden text-center mt-4">
                <h5 class="text-truncate font-size-15 ">Olá, {{ user.name }}.</h5>
                <p class="text-muted">Indentificamos que a sua plataforma ainda não está configurada.</p>
                <router-link :to="{ name: 'event' }">
                  <b-button variant="primary" size="lg" class="mt-4 mb-4" >
                    <i class="bx bx-cog font-size-16 align-middle mr-2"></i> CONFIGURAR AGORA!
                  </b-button>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="setting.id || configEvent">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-tabs no-pills content-class="p-3 text-muted" @input="tabEvent">
              <hr />
              <b-tab active>
                <template v-slot:title>
                  <div class="card-radio">
                    <div>
                      <i
                        class="mdi mdi-whatsapp font-size-18 text-success align-middle mr-2"
                      ></i>
                      <span>Whatsapp</span>
                    </div>
                  </div>
                </template>
                <form @submit.prevent="saveIntegration({ whatsapp_button: setting.whatsapp_button, whatsapp_button_local: setting.whatsapp_button_local, id: setting.id })">
                  <h4 class="card-title mb-4 ">Whatsapp</h4>
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label for="whatsapp">Número Whatsapp</label>
                        <input
                          id="whatsapp"
                          v-model="setting.whatsapp_button"
                          name="name"
                          type="text"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="form-group">
                        <label for="whatsapp">Local (Tela)</label>
                        <select
                          class="custom-select"
                          name="whatsapp_button_local"
                          id="whatsapp_button_local"
                          v-model="setting.whatsapp_button_local"
                        >
                          <option value="1">Todas</option>
                          <option value="2">Login</option>
                          <option value="3">Registro</option>
                          <option value="4">Login e Registro</option>
                          <option value="5">Páginas internas</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="mt-4">
                        <button type="submit" class="btn btn-primary mr-1">
                          Salvar
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <label class="mb-1 mt-3 fw-medium">Atenção</label>
                      <p class="text-muted font-13">Preencha o número do whatsapp para habilitar o botão de messagem direta na plataforma. </p>
                    </div>
                  </div>
                </form>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <div class="card-radio">
                    <div>
                      <i
                        class="mdi mdi-google font-size-18 text-danger align-middle mr-2"
                      ></i>
                      <span>Google</span>
                    </div>
                  </div>
                </template>
                <form @submit.prevent="saveIntegration({ tag_google_analytics: setting.tag_google_analytics, tag_google_gtm: setting.tag_google_gtm, id: setting.id })">
                  <h4 class="card-title mb-4 ">Google</h4>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label for="google_analytic">Tag Google Analytic</label>
                        <input
                          id="google_analytic"
                          v-model="setting.tag_google_analytics"
                          name="name"
                          type="text"
                          class="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label for="tag_google_gtm">Google Tag Manager</label>
                        <input
                          id="tag_google_gtm"
                          v-model="setting.tag_google_gtm"
                          name="name"
                          type="text"
                          class="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6">
                      <button type="submit" class="btn btn-primary mr-1">
                        Salvar
                      </button>
                    </div>
                    <div class="col-sm-12">
                      <label class="mb-1 mt-3 fw-medium">Atenção</label>
                      <p class="text-muted font-13">Preencha o campo com código do seu "Google Analytic 4" ou "Google Tag Manager" para que a plataforma envie todas as estatísticas de acessos do seu evento.</p>
                    </div>
                  </div>
                </form>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <div class="card-radio">
                    <div>
                      <i
                        class="mdi mdi-facebook font-size-18 text-primary align-middle mr-2"
                      ></i>
                      <span>Facebook</span>
                    </div>
                  </div>
                </template>
                <form @submit.prevent="saveIntegration({ pixel_facebook: setting.pixel_facebook, id: setting.id })">
                  <h4 class="card-title mb-4 ">Facebook</h4>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label for="pixel_facebook">Pixel Facebook</label>
                        <input
                          id="pixel_facebook"
                          v-model="setting.pixel_facebook"
                          name="name"
                          type="text"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="mt-4">
                        <button type="submit" class="btn btn-primary mr-1">
                          Salvar
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <label class="mb-1 mt-3 fw-medium">Atenção</label>
                      <p class="text-muted font-13">Preencha o campo com código do seu "Pixel" para que a plataforma envie os eventos para o facebook.</p>
                    </div>
                  </div>
                </form>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <div class="card-radio">
                    <div>
                      <i
                        class="fab fa-hotjar font-size-24 text-danger align-middle mr-2"
                      ></i>
                      <span>Hotjar</span>
                    </div>
                  </div>
                </template>
                <form @submit.prevent="saveIntegration({ tag_hotjar: setting.tag_hotjar, id: setting.id })">
                  <h4 class="card-title mb-4 ">Hotjar</h4>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label for="tag_hotjar">Tag Hotjar</label>
                        <input
                          id="tag_hotjar"
                          v-model="setting.tag_hotjar"
                          name="name"
                          type="text"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="mt-4">
                        <button type="submit" class="btn btn-primary mr-1">
                          Salvar
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <label class="mb-1 mt-3 fw-medium">Atenção</label>
                      <p class="text-muted font-13">Preencha o campo com a tag do "Hotjar", assim você saberá o comportamento dos seus usuários na plataforma</p>
                    </div>
                  </div>
                </form>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <div class="card-radio">
                    <div>
                      <i
                        class="mdi mdi-chat font-size-18 text-default align-middle mr-2"
                      ></i>
                      <span>Twak Chat</span>
                    </div>
                  </div>
                </template>
                <form @submit.prevent="saveIntegration({ tag_tawk: setting.tag_tawk, tag_tawk_local: setting.tag_tawk_local, id: setting.id })">
                  <h4 class="card-title mb-4 ">Tawk Chat</h4>
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label for="tag_tawk">Tag Tawk Chat</label>
                        <input
                          id="tag_tawk"
                          v-model="setting.tag_tawk"
                          name="name"
                          type="text"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="form-group">
                        <label for="tag_tawk_local">Local (Tela)</label>
                        <select
                          class="custom-select"
                          name="tag_tawk_local"
                          id="tag_tawk_local"
                          v-model="setting.tag_tawk_local"
                        >
                          <option value="1">Todas</option>
                          <option value="2">Login</option>
                          <option value="3">Registro</option>
                          <option value="4">Login e Registro</option>
                          <option value="5">Páginas internas</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="mt-4">
                        <button type="submit" class="btn btn-primary mr-1">
                          Salvar
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <label class="mb-1 mt-3 fw-medium">Atenção</label>
                      <p class="text-muted font-13">Preencha o campo com a tag do "Tawk", assim poderá monitorar e conversar com os visitantes do seu evento.</p>
                    </div>
                  </div>
                </form>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <div class="card-radio">
                    <div>
                      <i
                        class="mdi mdi-alpha-s-box font-size-18 text-primary align-middle mr-2"
                      ></i>
                      <span>Sympla</span>
                    </div>
                  </div>
                </template>
                <form @submit.prevent="saveToken()">
                  <h4 class="card-title mb-4 ">Sympla</h4>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label for="token">Token</label>
                        <input
                          id="token"
                          v-model="sympla.token"
                          name="token"
                          type="text"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="mt-4">
                        <button type="submit" class="btn btn-primary mr-1">
                          Salvar
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <label class="mb-1 mt-3 fw-medium">Atenção</label>
                      <p class="text-muted font-13">Preencha o campo com token da sympla.</p>
                    </div>
                  </div>
                </form>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <div class="card-radio">
                    <div>
                      <i
                        class="mdi mdi-video font-size-18 text-primary align-middle mr-2"
                      ></i>
                      <span>Zoom</span>
                    </div>
                  </div>
                </template>
                <form @submit.prevent="saveZoomTokens()">
                  <h4 class="card-title mb-4 ">Zoom</h4>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label for="zoom_api_key">API Key</label>
                        <input
                          id="zoom_api_key"
                          v-model="zoom.zoom_api_key"
                          name="zoom_api_key"
                          type="text"
                          class="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label for="zoom_api_secret">API Secret</label>
                        <input
                          id="zoom_api_secret"
                          v-model="zoom.zoom_api_secret"
                          name="zoom_api_secret"
                          type="text"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="mt-4">
                        <button type="submit" class="btn btn-primary mr-1">
                          Salvar
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <label class="mb-1 mt-3 fw-medium">Atenção</label>
                      <p class="text-muted font-13">Preencha os campos com as chaves  do seu JWT App. Caso não tenha você pode cria-lo através desse <a href="https://marketplace.zoom.us/develop/create" target="_blank">link</a></p>
                    </div>
                  </div>
                </form>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <div class="card-radio">
                    <div>
                      <i class="mdi mdi-unfold-less-horizontal font-size-18 text-primary align-middle mr-2"></i>
                      <span>RD Station</span>
                    </div>
                  </div>
                </template>
                <form @submit.prevent="saveRDStation()">
                  <h4 class="card-title mb-4 ">RD Station</h4>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label for="client_id">Client ID</label>
                        <input
                          id="client_id"
                          v-model="rdstation.client_id"
                          name="client_id"
                          type="text"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label for="client_secret">Client Secret</label>
                        <input
                          id="client_secret"
                          v-model="rdstation.client_secret"
                          name="client_secret"
                          type="text"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="mt-4">
                        <button type="submit" class="btn btn-primary mr-1">
                          Salvar
                        </button>
                        <span v-if="rdstation.id && rdstation.client_id && rdstation.client_secret && !rdstation.code">
                          <a :href="`https://api.rd.services/auth/dialog?client_id=${rdstation.client_id}&redirect_uri=${rdstation.callback}`" target="_blank">Validar integração com RD Station, clique aqui</a>
                        </span>
                        <span class="badge p-2 font-size-12 badge-soft-success" v-if="rdstation.code">Integração com RD Station ativa!</span>
                      </div>
                    </div>
                    <div class="col-sm-6" >
                      <div class="mt-4">
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-12">
                      <label class="mb-1 mt-3 fw-medium">Atenção</label>
                      <p class="text-muted font-13">Preencha o campo com client id e client secret da RD Station.</p>
                    </div>
                  </div>
                </form>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <div class="card-radio">
                    <div>
                      <i
                        class="mdi mdi-chat font-size-18 text-default align-middle mr-2"
                      ></i>
                      <span>App - Notificação</span>
                    </div>
                  </div>
                </template>
                <form @submit.prevent="sendPusherNot({ titlePusher, message })">
                  <h4 class="card-title mb-4 ">App - Notificação</h4>
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label for="titlePusher">Título</label>
                        <input
                          id="titlePusher"
                          v-model="titlePusher"
                          name="name"
                          type="text"
                          class="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="form-group">
                        <label for="message">Mensagem</label>
                        <input
                          id="message"
                          v-model="message"
                          name="name"
                          type="text"
                          class="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="mt-4">
                      <button type="submit" class="btn btn-primary">
                        Enviar
                      </button>
                    </div>
                  </div>
                </form>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="configSympla">
      <div class="col-lg-8">
        <PageHeader :title="'Sympla - Evento Vinculado'" :items="[]" />
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12">
                <SymplaEvents />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <PageHeader :title="'Sympla - Ingressos'" :items="[]" />
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12">
                <SymplaTickets />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from '@/components/layout/vertical'
import PageHeader from '@/components/page-header'
import appConfig from '@/app.config'
import SymplaEvents from './sympla/sympla-events/Index.vue'
import SymplaTickets from './sympla/sympla-tickets/Index.vue'
import { errorMessage, successMessage } from '@/components/alertsMessages'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    Layout,
    PageHeader,
    SymplaEvents,
    SymplaTickets
  },
  name: 'Integrações',
  page: {
    title: 'Integrações',
    meta: [{
      name: 'description',
      content: appConfig.description
    }]
  },
  data () {
    return {
      loading: true,
      configEvent: false,
      configSympla: false,
      titlePusher: '',
      message: '',
      title: 'Integrações',
      items: [{
        text: 'Meu Evento',
        href: '/'
      },
      {
        text: 'Integrações',
        active: true
      }
      ]
    }
  },
  mounted () {
    let loader = this.$loading.show()
    this.GetSetting().then(() => {
      loader.hide()
    })
    this.GetSympla().then(() => {
      console.log(this.sympla)
      loader.hide()
    })
    this.GetZoomKeys().then(() => {
      console.log(this.zoom)
      loader.hide()
    })
    this.getRD().then(() => {
      console.log(this.zoom)
      loader.hide()
    })
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('event', ['setting']),
    ...mapState('event', ['sympla']),
    ...mapState('rdstation', ['rdstation']),
    ...mapState('event', ['zoom'])
  },
  methods: {
    ...mapActions('event', {
      GetSetting: 'GetSetting',
      GetSympla: 'GetSympla',
      GetZoomKeys: 'GetZoom',
      postIntegration: 'SaveSettingIntegration',
      saveSympla: 'SaveSympla',
      saveZoomKey: 'SaveZoomKey',
      postNotification: 'sendNotification'
    }),
    ...mapActions('rdstation', {
      getRD: 'getRD',
      saveRD: 'save'
    }),
    tabEvent (index) {
      if (index === 5 && this.sympla.token) {
        this.configSympla = true
        return
      }
      this.configSympla = false
    },
    initSetting () {
      this.configEvent = true
    },
    async sendPusherNot (form) {
      let loader = this.$loading.show()
      try {
        await this.postNotification(form).then(() => {
          let message = 'Sucesso: Mensagem enviada.'
          let delay = 2500
          successMessage(message, delay)
          loader.hide()
          this.titlePusher = ''
          this.message = ''
        })
      } catch (err) {
        loader.hide()
        errorMessage('Alerta: ' + err.response.data.message)
      }
    },
    async saveIntegration (form) {
      let loader = this.$loading.show()
      try {
        await this.postIntegration(form).then(() => {
          let message = 'Sucesso: Dados atualizados com sucesso.'
          let delay = 2500
          successMessage(message, delay)
          loader.hide()
        })
      } catch (err) {
        loader.hide()
        errorMessage('Alerta: ' + err.response.data.message)
      }
    },
    async saveRDStation () {
      let loader = this.$loading.show()
      try {
        await this.saveRD(this.rdstation).then(() => {
          let message = 'Sucesso: Dados atualizados com sucesso.'
          let delay = 2500
          successMessage(message, delay)
          loader.hide()
        })
      } catch (err) {
        loader.hide()
        errorMessage('Alerta: ' + err.response.data.message)
      }
    },
    async saveToken () {
      let loader = this.$loading.show()
      try {
        await this.saveSympla(this.sympla).then(() => {
          let message = 'Sucesso: Dados atualizados com sucesso.'
          let delay = 2500
          successMessage(message, delay)
          this.tabEvent(5)
          loader.hide()
        })
      } catch (err) {
        loader.hide()
        errorMessage('Alerta: ' + err.response.data.message)
      }
    },
    async saveZoomTokens () {
      let loader = this.$loading.show()
      try {
        await this.saveZoomKey(this.zoom).then(() => {
          let message = 'Sucesso: Dados atualizados com sucesso.'
          let delay = 2500
          successMessage(message, delay)
          this.tabEvent(5)
          loader.hide()
        })
      } catch (err) {
        loader.hide()
        errorMessage('Alerta: ' + err.response.data.message)
      }
    }
  }
}
</script>

<style>
hr { margin-top:0 }
.nav-tabs { border: none !important}
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link { border: none !important}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus { border: none !important}
</style>
