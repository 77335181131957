import services from '../services'
import * as types from './mutation-types'

export const getRD = async ({ commit }, obj) => {
  await services.show(obj).then(res => {
    commit(types.SET_RDSTATION, res.data.data)
    return res.data.data
  })
}

export const save = async ({ commit }, obj) => {
  await services.saveRDStation(obj).then(res => {
    commit(types.SET_RDSTATION, res.data.data)
    return res
  })
}
