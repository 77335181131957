import http from '@/http'
const URL = '/admin/settings/rdstation'
export const show = () => http.get(URL)

export const saveRDStation = (payload) => {
  return http.post(URL, payload)
}

export default {
  show,
  saveRDStation
}
