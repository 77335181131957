var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"title":_vm.title,"size":"lg","no-enforce-focus":true,"no-close-on-backdrop":true},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.close()}}},[_vm._v("Cancelar")]),_c('button',{staticClass:"btn btn-primary mr-1",attrs:{"type":"submit"},on:{"click":function($event){return _vm.saveArchive()}}},[_vm._v("Salvar")])]},proxy:true}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('loading',{attrs:{"active":_vm.loading,"color":"#556ee6","loader":"dots","can-cancel":false,"is-full-page":false},on:{"update:active":function($event){_vm.loading=$event}}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.saveArchive()}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"description"}},[_vm._v("Descrição")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.archive.description),expression:"archive.description"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.submitted && _vm.$v.archive.description.$error
                },attrs:{"id":"description","name":"description","type":"text"},domProps:{"value":(_vm.archive.description)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.archive, "description", $event.target.value)}}}),(_vm.submitted && !_vm.$v.archive.description.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo descrição é obrigatório. ")]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"room_id"}},[_vm._v("Sala")]),_c('room-select',{class:{
                  'is-invalid': _vm.submitted && _vm.$v.archive.room_id.$error,
                },attrs:{"value":_vm.roomSelected,"classError":_vm.$v.archive.room_id.$error ? true : false},on:{"input":_vm.setRoom}})],1)]),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"url_download"}},[_vm._v("URL Download")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.archive.url_download),expression:"archive.url_download"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.submitted && _vm.$v.archive.url_download.$error
                },attrs:{"id":"url_download","name":"url_download","type":"text"},domProps:{"value":(_vm.archive.url_download)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.archive, "url_download", $event.target.value)}}}),(_vm.submitted && !_vm.$v.archive.url_download.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo url é obrigatório. ")]):_vm._e(),(_vm.submitted && !_vm.$v.archive.url_download.url)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo url download deve ser uma URL válida ")]):_vm._e()])])])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }