var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-modal',{attrs:{"title":_vm.title,"size":"lg","no-enforce-focus":true,"no-close-on-backdrop":true},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.close()}}},[_vm._v("Cancelar")]),_c('button',{staticClass:"btn btn-primary mr-1",attrs:{"type":"submit"},on:{"click":function($event){return _vm.saveReward()}}},[_vm._v("Salvar")])]},proxy:true}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('loading',{attrs:{"active":_vm.loading,"color":"#556ee6","loader":"dots","can-cancel":false,"is-full-page":false},on:{"update:active":function($event){_vm.loading=$event}}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.saveBanner()}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"type_reward"}},[_vm._v("Tipo")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.reward.type_reward),expression:"reward.type_reward"}],staticClass:"custom-select",class:{
                    'is-invalid': _vm.submitted && _vm.$v.reward.type_reward.$error,
                  },attrs:{"name":"type_reward","id":"type_reward"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.reward, "type_reward", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"1"}},[_vm._v("Digital")]),_c('option',{attrs:{"value":"2"}},[_vm._v("Físico")])]),(_vm.submitted && !_vm.$v.reward.type_reward.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo tipo é obrigatório. ")]):_vm._e()])]),_c('div',{staticClass:"col-sm-9"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"title"}},[_vm._v("Título")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.reward.title),expression:"reward.title"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.submitted && _vm.$v.reward.title.$error
                  },attrs:{"id":"title","name":"title","type":"text"},domProps:{"value":(_vm.reward.title)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.reward, "title", $event.target.value)}}}),(_vm.submitted && !_vm.$v.reward.title.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo título é obrigatório. ")]):_vm._e()])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"target"}},[_vm._v("Alvo "),_c('small',[_vm._v("(Qtd de indicações)")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.reward.target),expression:"reward.target"}],staticClass:"form-control text-right",class:{
                    'is-invalid': _vm.submitted && _vm.$v.reward.target.$error
                  },attrs:{"id":"target","name":"target","type":"number"},domProps:{"value":(_vm.reward.target)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.reward, "target", $event.target.value)}}}),(_vm.submitted && !_vm.$v.reward.target.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo alvo é obrigatório. ")]):_vm._e()])]),_c('div',{staticClass:"col-sm-3"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"qty_rewards"}},[_vm._v("Qtd de Recompensas")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.reward.qty_rewards),expression:"reward.qty_rewards"}],staticClass:"form-control text-right",class:{
                    'is-invalid': _vm.submitted && _vm.$v.reward.qty_rewards.$error
                  },attrs:{"id":"qty_rewards","name":"qty_rewards","type":"number"},domProps:{"value":(_vm.reward.qty_rewards)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.reward, "qty_rewards", $event.target.value)}}}),(_vm.submitted && !_vm.$v.reward.qty_rewards.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo Qtd é obrigatório. ")]):_vm._e()])]),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"url_reward"}},[_vm._v("URL da Recompensa")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.reward.url_reward),expression:"reward.url_reward"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.submitted && _vm.$v.reward.url_reward.$error
                  },attrs:{"id":"url_reward","name":"url_reward","type":"text"},domProps:{"value":(_vm.reward.url_reward)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.reward, "url_reward", $event.target.value)}}}),(_vm.submitted && !_vm.$v.reward.url_reward.$url)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" O campo url da recompensa precisa ser uma URL válida ")]):_vm._e()])])])])])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }